import { IUpWidgetInfo } from 'src/interfaces/user-mobile/main.interface';
import styled from 'styled-components';
import onClickLink from 'src/utils/onClickLink';
import { useNavigate } from 'react-router-dom';

function UpWidgetBoard({
  upWidgetList,
  currentUpWidgetIndex,
  subColor,
}: {
  upWidgetList: IUpWidgetInfo[];
  currentUpWidgetIndex: number;
  subColor: string;
}) {
  const navigate = useNavigate();
  return (
    <ContainerUpWidgetBoard
      onClick={event => onClickLink(event, navigate)}
      data-url={upWidgetList[currentUpWidgetIndex]?.url}
      plusBtnColor={subColor}
    >
      <h1>
        <UpWidgetElement current>
          {upWidgetList[currentUpWidgetIndex]?.title}
        </UpWidgetElement>
        <Plus>
          <PlusBtn>
            <Diagonal />
            <Hz />
            <Vt />
          </PlusBtn>
        </Plus>
      </h1>
    </ContainerUpWidgetBoard>
  );
}

export default UpWidgetBoard;

const Plus = styled.div``;
const PlusBtn = styled.div``;
const Diagonal = styled.div``;
const Vt = styled.div``;
const Hz = styled.div``;
const ContainerUpWidgetBoard = styled.div<{ plusBtnColor: string }>`
  position: relative;

  width: 100%;
  flex-shrink: 0;

  border-radius: 12px;
  background: #fff;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > h1 {
    width: 100%;
    height: 40px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px 16px 10px 16px;

    ${Plus} {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      ${PlusBtn} {
        position: relative;
        background-color: ${props => props.plusBtnColor};

        width: 20px;
        height: 20px;
        border-radius: 50%;

        ${Diagonal} {
          background-color: black;
          width: 12px;
          height: 1px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
          border-radius: 2px;
        }
        ${Hz} {
          background-color: black;
          width: 8px;
          height: 1px;
          position: absolute;
          left: 0;
          top: 0;
          transform: translate(6px, 5.5px);
          border-radius: 2px;
        }
        ${Vt} {
          background-color: black;
          width: 1px;
          height: 8px;
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(-5.5px, 6px);

          border-radius: 2px;
        }
      }
    }
  }
`;
const UpWidgetElement = styled.div<{ current: boolean }>`
  position: absolute;

  color: var(--Font-02_black, #111);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.35px;
`;
