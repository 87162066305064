import { FlexBox } from 'src/styles/OverviewStyle';
import styled from 'styled-components';
import handVote from 'src/assets/images/user-mobile/vote/HandVote.png';

function VoteBanner({ title, summary }: { title: string; summary: string }) {
  return (
    <>
      <BannerContainer>
        <h1>{title}</h1>
        <h2>{summary}</h2>
        <img src={handVote} alt="" />
      </BannerContainer>
    </>
  );
}

export default VoteBanner;

const BannerContainer = styled(FlexBox)`
  position: relative;
  width: 100%;
  height: 120px;
  background: ${({ theme }) => theme.colors.subColor};
  color: #04b014;
  font-weight: 700;

  padding: 16px;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h1 {
    width: 50%;
    color: var(--Font-02_black, #111);
    font-family: 'Paperlogy-8ExtraBold';
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px; /* 133.333% */
  }
  h2 {
    color: var(--Font-03_Gray, #505050);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;
  }
  img {
    position: absolute;
    width: 140px;
    height: auto;
    right: 16px;
    bottom: 0;
  }
`;
