import { IMenu } from 'src/interfaces/user-mobile/map.interface';
import styled from 'styled-components';
import defaultImg from 'src/assets/images/map/defaultImg.png';

function MenuBox({ image, name, summary, price }: IMenu) {
  return (
    <Container>
      <div>
        <p style={{ marginTop: 0 }}>{name}</p>
        <h2>{summary}</h2>
        <p>{Number(price).toLocaleString()}원</p>
      </div>
      {image ? <Img src={image} /> : <Img src={defaultImg} />}
    </Container>
  );
}
export default MenuBox;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 88px;
  flex-shrink: 0;

  margin-top: 12px;

  border-bottom: 1px solid #d9d9d9;

  p {
    margin-top: 8px;
    color: #111;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
  }
  h2 {
    margin-top: 2px;
    color: var(--Font-03_Gray, #505050);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;
  }
`;

export const Img = styled.img`
  width: 76px;
  height: 76px;
  border-radius: 4px;
`;
