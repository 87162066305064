import styled from 'styled-components';
import { IMenu } from 'src/interfaces/user-mobile/map.interface';
import MenuBox from './MenuBox';

function FullMenu({ menus }: { menus: IMenu[] }) {
  return (
    <MenuContainer>
      {menus.map((e, index) => (
        <MenuItemWrapper key={index.toString()}>
          <MenuBox
            image={e.image}
            name={e.name}
            summary={e.summary}
            price={e.price}
          />
        </MenuItemWrapper>
      ))}
    </MenuContainer>
  );
}
export default FullMenu;

export const MenuContainer = styled.div`
  margin-top: 8px;
  width: 100%;
  background-color: #fff;
  padding: 16px 16px;
  h1 {
    color: #111;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px; /* 140% */
    letter-spacing: -0.5px;
  }
  h2 {
    color: #111;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.375px;
  }
`;

export const MenuItemWrapper = styled.div`
  &:last-child {
    margin-bottom: 60px;
  }
`;
