import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TopFixedBarStamp from 'src/components/user-mobile/stamp/TopFixedBarStamp';
import { FlexBox } from 'src/styles/OverviewStyle';
import { Wrapper } from 'src/styles/user-mobile/OverviewStyle';
import styled from 'styled-components';
import check from 'src/assets/images/user-mobile/stamp/Check.svg';
import uncheck from 'src/assets/images/user-mobile/stamp/UnCheck.svg';
import { apiStampUser } from 'src/apis/utils/stamp';
import SignUpModal from 'src/components/user-mobile/stamp/SignUpModal';
import { BoardSetWidthStamp } from 'src/styles/user-mobile/StampStyle';
import StampFooter from 'src/components/user-mobile/stamp/StampFooter';
import StampStartForm from 'src/components/user-mobile/stamp/StampStartForm';
import ParticipantBox from 'src/components/user-mobile/stamp/ParticipantBox';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import setStampLocalStorage from 'src/utils/setStampLocalStorage';
import { IStampPostUserInfo } from 'src/interfaces/user-mobile/stamp.interface';
import { stampPostUserInfoBlank } from 'src/consts/user-mobile/blank.const';
import {
  IS_STAMP_COUNT_PARTICIPANT,
  IS_STAMP_CUSTOM,
} from 'src/consts/config.const';

function StampSignUp() {
  const stampId = useLocation().state?.stampData[0]?.stampId || 0;
  const stampOn = useLocation().state?.stampData[0]?.stampOn || true;
  const navigate = useNavigate();

  const [disabled, setDisabled] = useState(true);
  const [phoneRequired, setPhoneRequired] = useState(false);
  const [checkState, setCheckState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [addPersonCount, setAddPersonCount] = useState(0);
  const [userInfo, setUserInfo] = useState<IStampPostUserInfo>({
    ...stampPostUserInfoBlank,
  });

  const mutationStampUser = useMutation({
    mutationFn: ({
      id,
      requestUserInfo,
    }: {
      id: number;
      requestUserInfo: IStampPostUserInfo & { participantCount: number };
    }) => {
      if (IS_STAMP_CUSTOM)
        return apiStampUser.postStampUserCustom(id, requestUserInfo);
      return apiStampUser.postStampUser(id, requestUserInfo);
    },
  });

  const onChangeForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const onClickCheck = () => {
    setCheckState(prev => !prev);
    if (!checkState) setShowModal(true);
  };

  const onClickNumber = (type: string) => {
    if (type === 'plus') {
      if (addPersonCount < 3) setAddPersonCount(prev => prev + 1);
      else alert('최대 3명까지 추가하실 수 있습니다.');
    } else if (addPersonCount > 0) {
      setAddPersonCount(prev => prev - 1);
    }
  };

  const onClickSubmit = async () => {
    const tmp = IS_STAMP_COUNT_PARTICIPANT
      ? window.confirm(
          '추가 참여 인원은 추후 수정이 불가능합니다.\n작성하신 정보대로 스탬프 투어를 진행하시겠습니까?',
        )
      : true;
    const requestUserInfo = {
      ...userInfo,
      participantCount: IS_STAMP_COUNT_PARTICIPANT ? addPersonCount + 1 : 1,
    };

    console.log(requestUserInfo);
    if (tmp)
      try {
        const response = await mutationStampUser.mutateAsync({
          id: stampId,
          requestUserInfo,
        });

        setStampLocalStorage(
          stampId,
          response.data.uuid,
          userInfo.name,
          userInfo.phone,
          response.data.participantCount,
        );

        navigate('/stamp', { state: { firstVisit: true } });
      } catch (error) {
        const typedError = error as AxiosError<{ code: string }>; // AxiosError로 캐스팅
        const { code } = typedError.response?.data || {};

        if (code === 'C004') {
          alert('금일 스탬프 투어 신규 등록은 마감되었습니다.');
          navigate('/stamp/start');
        } else if (code === 'C005') alert('이미 등록된 번호입니다.');
      }
  };

  useEffect(() => {
    if (stampId) {
      if (!stampOn) {
        alert('금일 스탬프 투어 신규 등록은 마감되었습니다.');
        navigate('/stamp/start');
      }
    } else {
      alert(
        '스탬프 투어에 대한 정보가 없습니다.\n스탬프 시작페이지로 돌아갑니다.',
      );
      navigate('/stamp/start');
    }
  }, [stampId, stampOn]);

  useEffect(() => {
    const phoneRegex = /^010\d{8}$/;
    if (
      Object.values(userInfo).every(item => item !== '') &&
      phoneRegex.test(userInfo.phone) &&
      checkState
    )
      setDisabled(false);
    else setDisabled(true);

    if (phoneRegex.test(userInfo.phone)) setPhoneRequired(false);
    else setPhoneRequired(true);
  }, [userInfo, checkState]);

  return (
    <Wrapper>
      <TopFixedBarStamp />
      <BoardSetWidthStamp>
        <StampStartForm
          onChangeForm={onChangeForm}
          onClickSubmit={onClickSubmit}
          disabled={disabled}
          phoneRequired={phoneRequired}
          userInfo={userInfo}
          buttonText="시작하기"
        />
        <CheckBox onClick={onClickCheck}>
          <img src={checkState ? check : uncheck} alt="" />
          <p>개인정보 수집 및 활용 동의</p>
        </CheckBox>
        {IS_STAMP_COUNT_PARTICIPANT && (
          <ParticipantBox
            onClickNumber={onClickNumber}
            addPersonCount={addPersonCount}
          />
        )}

        <StampFooter />
      </BoardSetWidthStamp>
      {showModal && <SignUpModal setShowModal={setShowModal} />}
    </Wrapper>
  );
}

export default StampSignUp;

const CheckBox = styled(FlexBox)`
  cursor: pointer;

  margin-bottom: 20px;
  align-items: center;
  gap: 6px;
  > p {
    color: var(--Font-03_Gray, #505050);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;
    text-decoration-line: underline;
  }
`;
