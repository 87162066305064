import VoteBanner from 'src/components/user-mobile/vote/VoteBanner';
import VoteHeader from 'src/components/user-mobile/vote/VoteHeader';
import VoteBoard from 'src/components/user-mobile/vote/VoteBoard';
import { BoardSetWidth, Wrapper } from 'src/styles/user-mobile/OverviewStyle';
import { TopFixedBarBlank } from 'src/styles/user-mobile/TopFixedBarStyle';

function VotePreview() {
  return (
    <Wrapper>
      <BoardSetWidth>
        <VoteHeader text="투표하기" />
        <TopFixedBarBlank />
        <VoteBanner
          title="맘에 드는 항목에 투표하세요!"
          summary="여러분의 소중한 한 표를 기다립니다."
        />
        <VoteBoard />
      </BoardSetWidth>
    </Wrapper>
  );
}
export default VotePreview;
