import { useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { postBlank } from 'src/consts/user-mobile/blank.const';
import { IPost } from 'src/interfaces/user-mobile/post.interface';
import { useOutletContext, useParams } from 'react-router-dom';
import { IFestivalOutlet } from 'src/interfaces/overview.interface';
import { apiPost } from 'src/apis/utils/post';
import { typeState } from 'src/recoils/user-mobile.atom';
import { ListBoard, MainBoard } from 'src/styles/user-mobile/PostListStyle';
import { useQuery } from '@tanstack/react-query';
import PostListBox from './PostListBox';

function PostListBoard() {
  const params = useParams();
  const [type, setType] = useRecoilState(typeState);
  // 'notice' => 'NOTICE'

  const { festivalId } = useOutletContext<IFestivalOutlet>();

  const { data: postList } = useQuery({
    queryFn: () =>
      apiPost
        .getNoticeList(festivalId, type)
        .then(response =>
          response.data.noticeInquireListDto.sort(
            (a: IPost, b: IPost) =>
              new Date(b.time).getTime() - new Date(a.time).getTime(),
          ),
        )
        .catch(error => console.log(error)),
    queryKey: ['postList', festivalId, type],
    placeholderData: [{ ...postBlank }] as IPost[],
  });

  useEffect(() => {
    setType(params.type?.toUpperCase() || '');
  }, [params]);

  return (
    <ListBoard>
      <MainBoard>
        {postList?.map((item: IPost, index: number) => (
          <PostListBox item={item} indexProp={index} />
        ))}
      </MainBoard>
    </ListBoard>
  );
}

export default PostListBoard;
