import styled, { useTheme } from 'styled-components';
import { useNavigate, useOutletContext } from 'react-router-dom';

import {
  TopFixedBar,
  TopFixedBarBlank,
} from 'src/styles/user-mobile/TopFixedBarStyle';

import checkBtn from 'src/assets/images/user-mobile/CheckBtn.svg';
import bkBtn2 from 'src/assets/images/user-mobile/BackBtn2.svg';
import { useEffect, useRef, useState } from 'react';
import {
  IFestivalOutlet,
  IThemeColors,
} from 'src/interfaces/overview.interface';

import grayBlankDiv from 'src/assets/images/user-mobile/GrayBlankDiv.svg';
import {
  IConcertCategory,
  ILineUp,
} from 'src/interfaces/user-mobile/concert.interface';
import {
  concertCategoryBlank,
  concertInfoBlank,
  lineUpBlank,
} from 'src/consts/user-mobile/blank.const';
import {
  apiConcertInfo,
  apiConcertType,
  apiLineUp,
} from 'src/apis/utils/concert';
import {
  BoardSetWidth,
  Flex,
  MainBlock,
  RoundBox,
  Wrapper,
} from 'src/styles/user-mobile/OverviewStyle';
import { useRecoilValue } from 'recoil';
import { topTextState } from 'src/recoils/user-mobile.atom';
import { useQuery } from '@tanstack/react-query';

function FestivalInfo() {
  // 반응형을 위한 boradSet (위)//
  const topText = useRecoilValue(topTextState);
  const refBoardSet = useRef<HTMLDivElement>(null);
  const [mainBlockWidth, setMainBlockWidth] = useState(0);
  useEffect(() => {
    setMainBlockWidth((refBoardSet.current?.offsetWidth || 32) - 32);
  }, [refBoardSet]);
  // 반응형을 위한 boradSet (아래)//

  const navigate = useNavigate();
  const theme = useTheme();
  const { festivalId, todayOf } = useOutletContext<IFestivalOutlet>();

  const [todayOfIndex, setTodayOfIndex] = useState(0);

  const { data: lineUpList } = useQuery({
    queryKey: ['lineUpList', festivalId],
    queryFn: () =>
      apiLineUp
        .getLineUps(festivalId)
        .then(response =>
          response.data.concertGetListDto.sort(
            (a: ILineUp, b: ILineUp) => a.day - b.day,
          ),
        ),
    placeholderData: [{ ...lineUpBlank }],
  });

  const { data: entryInfo } = useQuery({
    queryKey: ['entryInfo', festivalId],
    queryFn: () =>
      apiConcertType
        .getConcertType(festivalId, 'entry')
        .then(response => response.data),
    placeholderData: { ...concertInfoBlank },
  });

  const { data: viewInfo } = useQuery({
    queryKey: ['viewInfo', festivalId],
    queryFn: () =>
      apiConcertType
        .getConcertType(festivalId, 'view')
        .then(response => response.data),
    placeholderData: { ...concertInfoBlank },
  });

  const { data: othersList } = useQuery({
    queryKey: ['othersList', festivalId],
    queryFn: () =>
      apiConcertInfo
        .getConcertInfos(festivalId, 'others')
        .then(response => response.data.concertInfoGetDto),
    placeholderData: [{ ...concertCategoryBlank }],
  });

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    let index;
    if (todayOf !== 0) {
      index = lineUpList?.findIndex((item: ILineUp) => item.day === todayOf);
      setTodayOfIndex(index >= 0 ? index : 0);
    }
  }, [todayOf, lineUpList]);

  return (
    <Wrapper>
      <BoardSetWidth ref={refBoardSet}>
        <TopFixedBar backgroundColor={theme.colors.backgroundColor}>
          <Flex style={{ marginLeft: '16px' }}>
            <BkBtn2 onClick={() => navigate(-1)} src={bkBtn2} />
            <H1>{topText}</H1>
          </Flex>
          {/* <MenuBtn /> */}
        </TopFixedBar>

        <TopFixedBarBlank />

        <MainBlock
          style={{ paddingBottom: '20px' }}
          mainBlockWidth={mainBlockWidth}
        >
          <LineUpBoard
            onClick={() => navigate('line_up')}
            fontColor={theme.colors.fontColor}
          >
            <ImgIdol src={lineUpList[todayOfIndex]?.thumbnail} />
            <TextPart>
              <h1>오늘의 라인업</h1>

              <Flex>
                <h2>확인하러 가기</h2>
                <img src={checkBtn} alt="라인업 이미지" />
              </Flex>
            </TextPart>
          </LineUpBoard>
          {entryInfo.summary && viewInfo.summary && (
            <InfoBoard>
              <button
                type="button"
                onClick={() => {
                  navigate('entrance');
                }}
              >
                <h1>입장 방법</h1>
                <h2>{entryInfo.summary}</h2>
                {entryInfo.icon && (
                  <RoundBox src={entryInfo.icon || grayBlankDiv} />
                )}
              </button>
              <InfoHR />
              <button
                type="button"
                onClick={() => {
                  navigate('view');
                }}
              >
                <h1>관람 안내</h1>
                <h2>{viewInfo.summary}</h2>
                {viewInfo.icon && (
                  <RoundBox src={viewInfo.icon || grayBlankDiv} />
                )}
              </button>
            </InfoBoard>
          )}

          {othersList.map((item: IConcertCategory) => (
            <ETCBtn
              onClick={() => {
                navigate(`others/${item.concertInfoId}`, {
                  state: { id: item.concertInfoId },
                });
              }}
            >
              <h1>{item.name}</h1>
              <h2>{item.summary}</h2>
            </ETCBtn>
          ))}
        </MainBlock>
      </BoardSetWidth>
    </Wrapper>
  );
}

export default FestivalInfo;

const ImgIdol = styled.img``;
const TextPart = styled(Flex)``;
const LineUpBoard = styled(Flex)<IThemeColors>`
  width: 100%;
  flex-direction: column;
  border: 12px;

  cursor: pointer;
  ${ImgIdol} {
    width: 100%;
    border-radius: 12px 12px 0 0;
  }

  ${TextPart} {
    background-color: white;
    width: 100%;
    border-radius: 0px 0px 12px 12px;

    padding: 16px;

    justify-content: space-between;
    align-items: center;
    h1 {
      color: #111;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
    }
    h2 {
      color: ${props => props.fontColor};
      font-size: 14px;
      font-style: normal;
      font-weight: 700;

      margin-right: 4px;
    }
  }
`;

const InfoBoard = styled(Flex)`
  width: 100%;
  height: 180px;
  flex-shrink: 0;

  border-radius: 12px;
  background: #fff;

  justify-content: space-between;

  button {
    position: relative;
    width: 50%;
    height: 180px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-start;

    padding: 16px;
    h1 {
      color: #111;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px; /* 144.444% */

      margin-bottom: 8px;
    }
    h2 {
      color: var(--Font-03_Gray, #505050);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    ${RoundBox} {
      position: absolute;
      right: 16px;
      bottom: 16px;

      width: 48px;
      height: 48px;
    }
  }
`;

const InfoHR = styled.div`
  width: 1px;
  height: 140px;
  background-color: #e5e5ec;
`;

const ETCBtn = styled.button`
  width: 100%;
  height: 56px;
  flex-shrink: 0;

  background-color: white;
  border-radius: 12px;

  padding: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    color: #111;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }

  h2 {
    color: var(--Font-03_Gray, #505050);
    text-align: right;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;
  }
`;

const BkBtn2 = styled.img`
  margin-right: 14px;
  cursor: pointer;
`;

const H1 = styled.h1`
  color: #111;

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
`;
