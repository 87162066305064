import { useState } from 'react';
import { FlexBoxFullWidth } from 'src/styles/OverviewStyle';
import styled from 'styled-components';
import {
  IInquiry,
  IInquiryInfo,
} from 'src/interfaces/user-mobile/inquiry.interface';
import { apiInquiry } from 'src/apis/utils/inquiry';
import {
  inquiryBlank,
  inquiryInfoBlank,
  EXCEPT_FOR_WEB_BOARD,
} from 'src/consts/user-web.const';
import { useQuery } from '@tanstack/react-query';
import WebInquiryForm from './WebInquiryForm/WebInquiryForm';
import WebInquiryDetail from './WebInquiryDetail';
import WebInquiryList from './WebInquiryList/WebInquiryList';

function WebInquiry({ festivalId }: { festivalId: number }) {
  const [currentPage, setCurrentPage] = useState('list');
  const [showModal, setShowModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [inquiryInfo, setInquiryInfo] = useState<IInquiryInfo>({
    ...inquiryInfoBlank,
  });
  const { data: inquiryList } = useQuery({
    enabled: currentPage === 'list',
    queryKey: ['inquiryList', festivalId, currentPage],
    queryFn: () =>
      apiInquiry
        .getInquiryListForUser(festivalId)
        .then(response =>
          response.data.inquiryList.sort(
            (a: IInquiry, b: IInquiry) =>
              new Date(b.createdDate).getTime() -
              new Date(a.createdDate).getTime(),
          ),
        )
        .catch(error => console.log(error)),
    placeholderData: [{ ...inquiryBlank }],
  });

  return (
    <ContainerInquiry>
      {currentPage === 'list' && (
        <WebInquiryList
          inquiryList={inquiryList}
          setCurrentPage={setCurrentPage}
          showModal={showModal}
          setShowModal={setShowModal}
          setInquiryInfo={setInquiryInfo}
        />
      )}
      {currentPage === 'form' && (
        <WebInquiryForm
          festivalId={festivalId}
          setCurrentPage={setCurrentPage}
        />
      )}
      {currentPage === 'detail' && (
        <WebInquiryDetail
          inquiryInfo={inquiryInfo}
          setCurrentPage={setCurrentPage}
        />
      )}
    </ContainerInquiry>
  );
}
export default WebInquiry;

const ContainerInquiry = styled(FlexBoxFullWidth)`
  min-height: ${window.innerHeight - EXCEPT_FOR_WEB_BOARD}px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
