import { Wrapper } from 'src/styles/user-mobile/OverviewStyle';
import {
  BoardSetWidthStamp,
  StampSuccessModal,
} from 'src/styles/user-mobile/StampStyle';
import styled from 'styled-components';
import x from 'src/assets/images/user-mobile/stamp/X2.svg';
import { useNavigate } from 'react-router-dom';
import QrMaker from 'src/components/user-mobile/stamp/QrMaker';
import { BlackBackground, FlexBox } from 'src/styles/OverviewStyle';
import { useEffect, useState } from 'react';
import { STAMP_FONT, STAMP_USER_JOB } from 'src/assets/database/stampData';
import { apiStampUser } from 'src/apis/utils/stamp';

import refresh from 'src/assets/images/user-mobile/stamp/Refresh.svg';

function StampFinished() {
  const uuid = localStorage.getItem('uuid') || '';
  const name = localStorage.getItem('name') || '';
  const phone = localStorage.getItem('phone') || '';
  const participantCount = localStorage.getItem('participantCount') || '';
  const [showModal, setShowModal] = useState(false);
  const [finished, setFinished] = useState(false);
  const navigate = useNavigate();

  // useQuery() 필요 없음
  const fetchCheck = () => {
    apiStampUser
      .getStampUser(uuid)
      .then(response => {
        if (response.data.finished) setFinished(true);
        else setFinished(false);
      })
      .catch(error => console.log(error));
  };

  const onClickFinish = () => {
    if (finished) navigate('/stamp');
    else setShowModal(false);
  };

  useEffect(() => {
    fetchCheck();
  }, []);

  return (
    <Wrapper>
      <StyledBoardSetWidthStamp>
        <XButton onClick={() => navigate('/stamp')} src={x} alt="스탬프 홈" />
        <HeaderText>경품 인증 QR코드</HeaderText>
        <QrMaker value={uuid} />
        <CautionText>QR 코드를 부스 운영자에게 보여주세요.</CautionText>
        <ContentText>
          <h1>{STAMP_USER_JOB}정보</h1>
          <h2>참여자 : {name}</h2>
          <h2>전화번호 : {phone}</h2>
          <h2>참여인원 : {participantCount}</h2>
        </ContentText>
        <FinishButtonBox>
          <FinishButton disabled={!finished} onClick={() => setShowModal(true)}>
            경품 수령 확인
          </FinishButton>
          <RefreshButton>
            <img src={refresh} alt="새로고침" onClick={fetchCheck} />
          </RefreshButton>
        </FinishButtonBox>
      </StyledBoardSetWidthStamp>

      {showModal && (
        <BlackBackground>
          <StampSuccessModal>
            <h1>상품 수령 완료</h1>
            <button type="button" onClick={onClickFinish}>
              확인
            </button>
          </StampSuccessModal>
        </BlackBackground>
      )}
    </Wrapper>
  );
}

export default StampFinished;

const StyledBoardSetWidthStamp = styled(BoardSetWidthStamp)`
  position: relative;
  padding: 0;
`;
const XButton = styled.img`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

const HeaderText = styled.h1`
  color: var(--Font-02_black, #111);
  text-align: center;
  font-family: ${STAMP_FONT};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
  margin-bottom: 8px;
`;

const CautionText = styled.h2`
  color: var(--Font-02_black, #111);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.375px;
  margin-bottom: 28px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentText = styled(FlexBox)`
  width: 80%;
  padding: 14px 18px;
  flex-shrink: 0;

  border-radius: 8px;
  background: #fcf2e7;
  margin-bottom: 12px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > h1 {
    color: ${({ theme }) => theme.colors.fontColor};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  > h2 {
    color: #111;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
  }
`;
const FinishButtonBox = styled(FlexBox)`
  width: 80%;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
`;
const FinishButton = styled.button<{ disabled: boolean }>`
  flex-grow: 1;

  height: 52px;
  bottom: 28px;
  padding: 12px;
  flex-shrink: 0;
  border-radius: 6px;
  background: ${({ disabled }) => (disabled ? '#999' : '#111')};
  color: ${({ disabled }) => (disabled ? '#F1F1F5' : '#fff')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.4px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
const RefreshButton = styled.button`
  flex-shrink: 0;

  width: 52px;
  height: 52px;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
`;
