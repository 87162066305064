import axios from 'axios';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
});

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    const requestConfig = { ...config };
    requestConfig.headers.withCredentials = true;
    if (token) {
      requestConfig.headers.Authorization = `Bearer ${token}`;
    }
    return requestConfig;
  },
  error => Promise.reject(error),
);
