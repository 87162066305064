/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { apiCategory } from 'src/apis/utils/map';
import { IFestivalOutlet } from 'src/interfaces/overview.interface';
import { ICategoryInfo } from 'src/interfaces/user-mobile/map.interface';
import { activeCategoryState } from 'src/recoils/user-mobile.atom';
import styled from 'styled-components';
import CategoryBox from './category/CategoryBox';

interface ICategory {
  isManage: boolean;
  // 상태 변화 코드의 타입은 Dispatch의 제네릭으로 SetStateAction 전달 후 타입 지정
}

function Category(props: ICategory) {
  const { festivalId } = useOutletContext<IFestivalOutlet>();
  const setActiveCategory = useSetRecoilState(activeCategoryState);

  const [categoryBoard, setCategoryBoard] = useState<ICategoryInfo[]>([]);

  useEffect(() => {
    apiCategory
      .getCategory(festivalId)
      .then(response => {
        if (response.data.length > 0) {
          setCategoryBoard(
            response.data.sort(
              (a: ICategoryInfo, b: ICategoryInfo) => a.rank - b.rank,
            ),
          );
          setActiveCategory(response.data[1].mapCategoryId);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <Container isManage={props.isManage}>
      {categoryBoard.map((e: ICategoryInfo) => {
        if (e.categoryName !== '고정 부스') {
          return <CategoryBox categoryInfo={e} />;
        }
      })}
    </Container>
  );
}

const Container = styled.div<{ isManage: boolean }>`
  width: 100vw;
  display: flex;
  position: absolute;
  z-index: 1;
  margin-top: 56px;

  overflow-x: scroll;
  white-space: nowrap; // 두줄로 안나오게 하기.
  &::-webkit-scrollbar {
    display: none; //스크롤 없애기
  }

  ${props => (props.isManage ? 'width: 274px;  margin-top: 12px;' : '')}
`;

export default Category;
