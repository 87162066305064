import { FlexBoxFullWidth } from 'src/styles/OverviewStyle';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { apiPost } from 'src/apis/utils/post';
import { IPost } from 'src/interfaces/user-mobile/post.interface';
import { INoticeInfo } from 'src/interfaces/user-web.interface';
import { useQueries, useQuery } from '@tanstack/react-query';
import { postBlank } from 'src/consts/user-mobile/blank.const';
import { noticeInfoBlank } from 'src/consts/user-web.const';
import WebNoticeDetail from './WebNoticeDetail';
import WebNoticeList from './WebNoticeList';

function WebNotice({ festivalId }: { festivalId: number }) {
  const [currentId, setCurrentId] = useState(0);
  const [showDetail, setShowDetail] = useState(false);
  const { data: noticeList } = useQueries({
    queries: [
      {
        queryKey: ['noticeList', festivalId, 'NOTICE'],
        queryFn: () =>
          apiPost
            .getNoticeList(festivalId, 'NOTICE')
            .then(response => response.data.noticeInquireListDto)
            .catch(error => console.log('noticeError', error)),
        placeholderData: [{ ...postBlank }] as IPost[],
      },
      {
        queryKey: ['noticeList', festivalId, 'EVENT'],
        queryFn: () =>
          apiPost
            .getNoticeList(festivalId, 'EVENT')
            .then(response => response.data.noticeInquireListDto)
            .catch(error => console.log('eventError', error)),
        placeholderData: [{ ...postBlank }] as IPost[],
      },
    ],
    combine: results => {
      // results의 속성들을 어떤 이름, 어떤 형태로 반환할지 결정
      // 각 query들의 결과가 results에 담겨서 들어옴
      /* 단, 각 result는 배열들로서 단순히 data:result.map(item=>item.data)로 반환하게 되면
      [[배열1], [배열2]] 이렇게 원하지 않는 형태로 되니 주의. '...'로 직접 하나하나 push해서 배열 완성 필요 */
      const dataList = results.map(item => item.data);
      const tmpList = [] as IPost[];

      dataList.forEach(item => {
        // item이 [{ ...postBlank }]가 아닌 경우에만 tmpList에 추가 (단순 초기값이 아닌 경우에만)
        // 비어있는 항목이 나오는 버그 해결
        if (JSON.stringify(item) !== JSON.stringify([{ ...postBlank }]))
          tmpList.push(...item);
      });

      return {
        data: tmpList.sort(
          (a: IPost, b: IPost) =>
            new Date(b.time).getTime() - new Date(a.time).getTime(),
        ),
      };
    },
  });

  const { data: noticeInfo } = useQuery({
    queryKey: ['noticeInfo', festivalId, currentId],
    queryFn: () =>
      apiPost
        .getNoticeDetail(festivalId, currentId)
        .then(response => response.data)
        .catch(error => console.log(error)),
    placeholderData: { ...noticeInfoBlank } as INoticeInfo,
  });

  const onClickNoticeBox = (event: React.MouseEvent<HTMLDivElement>) => {
    const { id } = event.currentTarget;
    setCurrentId(Number(id));
    setShowDetail(true);
    window.scrollTo(0, 0);
  };

  const onClickBackButton = () => {
    setShowDetail(false);
  };

  useEffect(() => {
    if (!showDetail) setCurrentId(0);
  }, [showDetail]);

  return (
    <ContainerNotice>
      {showDetail ? (
        <WebNoticeDetail
          noticeInfo={noticeInfo}
          onClickBackButton={onClickBackButton}
        />
      ) : (
        <WebNoticeList
          noticeList={noticeList}
          onClickNoticeBox={onClickNoticeBox}
        />
      )}
    </ContainerNotice>
  );
}
export default WebNotice;

const ContainerNotice = styled(FlexBoxFullWidth)`
  min-height: ${window.innerHeight - 432}px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
