/* eslint-disable @typescript-eslint/no-unused-vars */

import grayBlankDiv from 'src/assets/images/user-mobile/GrayBlankDiv.svg';
import { RoundBox } from 'src/styles/user-mobile/OverviewStyle';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import getTranslateString from 'src/utils/getTranslateString';
import { useRecoilValue } from 'recoil';
import { activeLanguageState } from 'src/recoils/user-mobile.atom';
import { IWidgetInfo } from 'src/interfaces/user-mobile/main.interface';
import { useNavigate } from 'react-router-dom';
import onClickLink from 'src/utils/onClickLink';

function WidgetBoard({ item }: { item: IWidgetInfo }) {
  const navigate = useNavigate();
  const lang = useRecoilValue(activeLanguageState);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (lang === 'KOR') setName(item.name);
    else if (item.name !== '')
      getTranslateString(item.name).then(res => {
        setName(res);
      });
    if (lang === 'KOR') setDescription(item.description.replace(/\\n/g, '\n'));
    else if (item.description.replace(/\\n/g, '\n') !== '')
      getTranslateString(item.description.replace(/\\n/g, '\n')).then(res => {
        setDescription(res);
      });
  }, [lang, item]);

  return (
    <WidgetElement
      onClick={event => onClickLink(event, navigate)}
      data-url={item.url}
    >
      <h2>{description}</h2>
      <h1>{name}</h1>
      <RoundBox src={item.icon ? item.icon : grayBlankDiv} />
    </WidgetElement>
  );
}

export default WidgetBoard;

const WidgetElement = styled.div`
  position: relative;

  width: 152px;
  height: 148px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #fff;

  padding: 16px;

  display: flex;
  flex-direction: column;
  cursor: pointer;
  h1 {
    color: #111;
    height: 26px;
    /* headline1 */
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 144.444% */

    margin-bottom: 4px;
  }

  h2 {
    color: var(--Font-03_Gray, #505050);

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: -0.3px;

    white-space: pre-line;
  }

  ${RoundBox} {
    position: absolute;
    width: 48px;
    height: 48px;

    right: 16px;
    bottom: 16px;
  }
`;
