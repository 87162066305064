import React, { useEffect } from 'react';
import { apiFestival } from 'src/apis/utils/basic';
import { DefaultTheme } from 'src/global/theme';
import { IGetDuration } from 'src/interfaces/user-mobile/basic.interface';
import { ITheme } from 'src/interfaces/overview.interface';
import getDateString from 'src/utils/getDateString';

function useGetFestivalInfo(
  festivalId: number,
  setTheme: React.Dispatch<React.SetStateAction<ITheme>>,
  setTitle: React.Dispatch<React.SetStateAction<string>>,
  setLogoSrc: React.Dispatch<React.SetStateAction<string>>,
  setTodayOf: React.Dispatch<React.SetStateAction<number>>,
  setLatLng: React.Dispatch<React.SetStateAction<naver.maps.LatLng>>,
) {
  useEffect(() => {
    if (festivalId !== 0) {
      apiFestival
        .getFestival(festivalId)
        .then(res => {
          const { colors } = res.data;
          const tmp = {
            colors: {
              backgroundColor:
                colors.backgroundColor || DefaultTheme.colors.backgroundColor,
              mainColor: colors.mainColor || DefaultTheme.colors.mainColor,
              subColor: colors.subColor || DefaultTheme.colors.subColor,
              fontColor: colors.fontColor || DefaultTheme.colors.fontColor,
            },
          };
          setLatLng(
            new naver.maps.LatLng(res.data.latitude, res.data.longitude),
          );
          setTheme(tmp);
          setTitle(res.data.name);
          setLogoSrc(res.data.logo);
        })
        .catch(err => alert(err));

      apiFestival
        .getDuration(festivalId)
        .then(res => {
          const todayString = getDateString(new Date());

          const { durations } = res.data;
          const index = durations.findIndex(
            (item: IGetDuration) => item.date === todayString,
          );

          if (index >= 0) setTodayOf(durations[index].day);
        })
        .catch(error => console.log(error));
    }
  }, [festivalId]);
}

export default useGetFestivalInfo;
