import getTranslateString from 'src/utils/getTranslateString';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { apiFestival } from 'src/apis/utils/basic';
import { IDuration } from 'src/interfaces/user-mobile/basic.interface';
import { IFestivalOutlet } from 'src/interfaces/overview.interface';
import { dayState } from 'src/recoils/user-mobile.atom';
import * as S from './DayPopupStyle';

function DayPopup({
  setDayPopup,
}: {
  setDayPopup: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [dateText, setDateText] = useState('날짜 선택');
  const { festivalId } = useOutletContext<IFestivalOutlet>();
  const setDay = useSetRecoilState(dayState);
  const [days, setDays] = useState<IDuration[]>([]);
  const [translatedDays, setTranslatedDays] = useState<string[]>([]); // 번역된 일차 상태 추가

  const handleDay = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDay(Number(event.currentTarget.id));
    setDayPopup(false);
  };

  useEffect(() => {
    // '날짜 선택' 번역
    getTranslateString('날짜 선택').then(res => {
      setDateText(res);
    });

    // 일차 목록 가져오기 및 번역
    apiFestival
      .getDuration(festivalId)
      .then(res => {
        const fetchedDays = res.data.durations;
        setDays(fetchedDays);

        // 각각의 일차를 번역
        return Promise.all(
          fetchedDays.map((e: IDuration) => getTranslateString(`${e.day}일차`)),
        );
      })
      .then(translations => {
        setTranslatedDays(translations); // 번역된 일차 설정
      })
      .catch(err => {
        console.log(err);
      });
  }, [festivalId]);

  return (
    <S.Container
      onClick={prev => {
        setDayPopup(!prev);
      }}
    >
      <S.BoxContainer
        onClick={event => {
          event.stopPropagation(); // 부모 태그의 이벤트가 퍼지지 않도록 함.
        }}
      >
        <h1>{dateText}</h1>
        {days.map((e, index) => (
          <button type="button" id={e.day.toString()} onClick={handleDay}>
            {translatedDays[index] || `${e.day}일차`}
          </button>
        ))}
      </S.BoxContainer>
    </S.Container>
  );
}

export default DayPopup;
