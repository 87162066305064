import styled from 'styled-components';
import like from 'src/assets/images/user-mobile/like.svg';
import { FlexBox } from 'src/styles/OverviewStyle';
import { IVote } from 'src/interfaces/user-mobile/vote.interface';
import { voteBlank } from 'src/consts/user-mobile/blank.const';
import { useOutletContext } from 'react-router-dom';
import { IFestivalOutlet } from 'src/interfaces/overview.interface';
import { apiVote } from 'src/apis/utils/vote';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

function VoteBoard() {
  const { festivalId } = useOutletContext<IFestivalOutlet>();
  const { data: voteData } = useQuery({
    queryKey: ['voteList', festivalId],
    queryFn: () =>
      apiVote.getVoteList(festivalId).then(response => response.data.voteList),
    placeholderData: [{ ...voteBlank }],
  });
  const [voteList, setVoteList] = useState<IVote[]>(voteData);

  const onClickLike = (event: React.MouseEvent<HTMLButtonElement>) => {
    const voteId = Number(event.currentTarget.id);
    const index = Number(event.currentTarget.dataset.index);

    apiVote
      .postVoteLike(voteId)
      .then(response => {
        if (!localStorage.getItem('timestamp'))
          localStorage.setItem('timestamp', response.data.ulid);
        const tmpList = [...voteList];
        tmpList[index].likeCnt += 1;
        setVoteList([...tmpList]);
      })
      .catch(() => {
        alert('이미 좋아요를 눌러주셨습니다.');
      });
  };

  useEffect(() => {
    setVoteList(voteData);
  }, [voteData]);

  return (
    <VoteContainer>
      {voteList?.map((item: IVote, index: number) => (
        <VoteBox key={item?.id}>
          <LeftBox>
            <VoteTitle>{item?.title}</VoteTitle>
            <VoteContent>{item?.content}</VoteContent>
            <Liked>
              <span>{item?.likeCnt}</span>명이 이 공약을 좋아해요!
            </Liked>
          </LeftBox>
          <LikeButton
            id={item.id?.toString()}
            data-index={index}
            onClick={onClickLike}
          >
            <StyledLikeImg src={like} alt="like-icon" />
            좋아요
          </LikeButton>
        </VoteBox>
      ))}
    </VoteContainer>
  );
}

export default VoteBoard;

const VoteContainer = styled.div`
  width: 100%;
  background: #fff;
`;

const VoteBox = styled(FlexBox)`
  width: 100%;
  position: relative;
  padding: 0 16px 0 16px;

  border-bottom: 1px solid var(--Line-Light_Color, #f1f1f5);
`;

const LeftBox = styled(FlexBox)`
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const VoteTitle = styled.div`
  margin-top: 16px;

  color: #111;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.45px;
  margin-bottom: 2px;
  width: 243px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const VoteContent = styled.div`
  color: #505050;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.35px;
  margin-bottom: 6px;

  width: 80%;

  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Liked = styled.div`
  color: #505050;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.3px;
  margin-bottom: 18px;
  span {
    color: ${({ theme }) => theme.colors.fontColor};
    font-weight: 200;
  }
`;

const StyledLikeImg = styled.img`
  width: 28px;
  height: 28px;
  margin: 6px 14px 0 14px;
`;

const LikeButton = styled.button`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);

  width: 56px;
  height: 56px;
  border-radius: 16px;
  cursor: pointer;
  pointer-events: auto;
  background-color: #f8ebec;
  color: var(--Font-02_black, #111);

  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.275px;
  border: none;
  outline: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
