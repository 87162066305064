import { instance } from '..';

export const apiVote = {
  postVoteLike: (voteId: number) =>
    instance.post(
      `/vote/client/${voteId}/like`,
      {},
      {
        headers: { 'X-ULID': localStorage.getItem('timestamp') },
        withCredentials: true,
      },
    ),
  getVoteList: (festivalId: number) =>
    instance.get(`/vote/client`, { params: { festivalId } }),
};
