import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { postInfoBlank } from 'src/consts/user-mobile/blank.const';
import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import { apiPost } from 'src/apis/utils/post';
import { IFestivalOutlet } from 'src/interfaces/overview.interface';
import { IImageInfo } from 'src/interfaces/user-mobile/overview.interface';
import { Flex, InfoImageBoard } from 'src/styles/user-mobile/OverviewStyle';

import getTranslateString from 'src/utils/getTranslateString';

import imageBlank from 'src/assets/images/user-mobile/GrayBlank.png';
import { TopFixedBarBlank } from 'src/styles/user-mobile/TopFixedBarStyle';
import { useQuery } from '@tanstack/react-query';
import CustomSliderPost from '../CustomSliderPost';

import * as S from './PostDetailBoardStyle';
import MarkdownViewer from '../MarkDownViewer';

function PostDetailBoard() {
  const location = useLocation();
  const type = location.pathname.split('/')[0];
  const [index, setIndex] = useState('');
  const [title, setTitle] = useState('');
  const [writer, setWriter] = useState('');

  const noticeId = Number(useParams().id);
  const { festivalId } = useOutletContext<IFestivalOutlet>();

  const theme = useTheme();

  const { data: postInfo } = useQuery({
    queryKey: ['postInfo', festivalId, noticeId],
    queryFn: () =>
      apiPost
        .getNoticeDetail(festivalId, noticeId)
        .then(response => response.data)
        .catch(error => console.log(error)),
    placeholderData: { ...postInfoBlank, type },
  });

  useEffect(() => {
    if (localStorage.getItem('language') !== 'ko') {
      if (postInfo.index !== '')
        getTranslateString(postInfo.index).then(res => {
          setIndex(res);
        });
      if (postInfo.title !== '')
        getTranslateString(postInfo.title).then(res => {
          setTitle(res);
        });
      if (postInfo.writer !== '')
        getTranslateString(postInfo.writer).then(res => {
          setWriter(res);
        });
    } else {
      setIndex(postInfo.index);
      setTitle(postInfo.title);
      setWriter(postInfo.writer);
    }
  }, [postInfo]);

  return (
    <S.PostContainer>
      <TopFixedBarBlank />
      <Flex>
        {postInfo.content && (
          <S.SliderBoardPost>
            <CustomSliderPost imagesInfo={postInfo.images} />
          </S.SliderBoardPost>
        )}
      </Flex>
      <S.MainTextBoard fontColor={theme.colors.fontColor}>
        <h6>{index}</h6>
        <h1>{title}</h1>
        <h2>
          {writer}
          &nbsp;&nbsp;
          {postInfo?.createTime.slice(0, 10)}
          &nbsp;
          {postInfo?.createTime.slice(11, 19)}
        </h2>

        {postInfo.content && <MarkdownViewer content={postInfo.content} />}
      </S.MainTextBoard>

      {!postInfo.content && (
        <InfoImageBoard>
          {postInfo?.images.map((item: IImageInfo) => (
            <img
              key={item.image}
              id={item.imageId?.toString()}
              src={item.image || imageBlank}
              alt="게시글 이미지"
            />
          ))}
        </InfoImageBoard>
      )}
    </S.PostContainer>
  );
}

export default PostDetailBoard;
