export const INIT_MAP_OPTION = {
  center: new naver.maps.LatLng(37.50439823071351, 126.95660644992438),
  zoom: 18,
  minZoom: 15,
  baseTileOpacity: 1,
  background: 'white',
  tileSpare: 2,
  mapDataControl: false,
};

export const INIT_CENTER = {
  cau: new naver.maps.LatLng(37.50439823071351, 126.95660644992438),
  khu: new naver.maps.LatLng(37.244300956840064, 127.07883981595111),
  jejulhfestival: new naver.maps.LatLng(33.54351975987334, 126.66985062379958),
};
