import getTranslateString from 'src/utils/getTranslateString';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import timeImg from 'src/assets/images/map/timeImg.svg';
import { FlexBox } from 'src/styles/OverviewStyle';
import { IGetBoothSingle } from 'src/interfaces/user-mobile/map.interface';
import defaultImg from 'src/assets/images/map/defaultImg.png';
import { Name, Summary, Time } from '../ClickPopup/ClickPopupStyle';

function FullInfo({
  name,
  operationHours,
  summary,
  thumbnail,
}: IGetBoothSingle) {
  const [translatedName, setTranslatedName] = useState(name);
  const [translatedOperationHours, setTranslatedOperationHours] =
    useState(operationHours);
  const [translatedSummary, setTranslatedSummary] = useState(summary);

  useEffect(() => {
    const translateInfo = async () => {
      let nameTranslation = '';
      let operationHoursTranslation = '';
      let summaryTranslation = '';

      if (name !== '') nameTranslation = await getTranslateString(name);
      if (operationHours !== '')
        operationHoursTranslation = await getTranslateString(operationHours);
      if (summary !== '')
        summaryTranslation = await getTranslateString(summary);

      setTranslatedName(nameTranslation);
      setTranslatedOperationHours(operationHoursTranslation);
      setTranslatedSummary(summaryTranslation);
    };

    translateInfo(); // 번역 함수 호출
  }, [name, operationHours, summary]); // name, operationHours, summary 값이 변경될 때마다 호출

  return (
    <Container>
      <InfoBox>
        <Name>{translatedName}</Name>

        <SummaryBox>
          <Summary style={{ textOverflow: 'unset', whiteSpace: 'wrap' }}>
            {translatedSummary}
          </Summary>
        </SummaryBox>
        <FlexBox style={{ marginTop: '8px' }}>
          <TimeImg src={timeImg} />
          <Time>{translatedOperationHours}</Time>
        </FlexBox>
      </InfoBox>
      {thumbnail ? (
        <ThumbnailImg src={thumbnail} alt="부스 썸네일" />
      ) : (
        <ThumbnailImg src={defaultImg} alt="부스 썸네일" />
      )}
    </Container>
  );
}

export default FullInfo;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: #fff;
  padding: 16px;
  box-sizing: border-box;
`;

const InfoBox = styled.div`
  flex: 1;
  margin-right: 12px;
  max-width: calc(100% - 92px); // 이미지와 간격만큼 뺀 값
`;

const SummaryBox = styled.div`
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 라인 수 */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

const TimeImg = styled.img`
  margin-right: 4px;
`;

const ThumbnailImg = styled.img`
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 4px;
`;
