import styled from 'styled-components';

import bkBtn2 from 'src/assets/images/user-mobile/BackBtn2.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { typeState } from 'src/recoils/user-mobile.atom';
import { Flex } from 'src/styles/user-mobile/OverviewStyle';
import { MovingBar } from 'src/styles/user-mobile/MovingBarStyle';

import {
  BkBtn,
  SelectBar,
  TopFixedBar,
  TopFixedBlock,
} from 'src/styles/user-mobile/TopFixedBarStyle';
import getTranslateString from 'src/utils/getTranslateString';

function TopFixedBarPostList() {
  const [notice, setNotice] = useState('공지사항');
  const [event, setEvent] = useState('이벤트');

  const navigate = useNavigate();
  const refSelectBar = useRef<HTMLDivElement>(null);
  // <HTMLDivElement>이거 안 하면 refSelectBar?.current?.offsetWidth 할 때 오류남

  const [type, setType] = useRecoilState(typeState);
  const [pos, setPos] = useState<string>('');
  // 애니메이션 적용할 때 그냥 빈값을 주면 초기위치 잘 찾아감

  const onClickNotice = () => {
    setType('NOTICE');
    navigate('/post/notice');
    window.scrollTo(0, 0);
  };
  const onClickEvent = () => {
    setType('EVENT');
    navigate('/post/event');
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (type === 'NOTICE') setPos('25%');
    else if (type === 'EVENT') setPos('75%');
  }, [type]); // useRef 연습

  useEffect(() => {
    getTranslateString('공지사항').then(res => {
      setNotice(res);
    });

    getTranslateString('이벤트').then(res => {
      setEvent(res);
    });
  }, []);

  return (
    <TopFixedBlock>
      <StyledTopFixedBar>
        <Flex style={{ marginLeft: '16px' }}>
          <BkBtn onClick={() => navigate(-1)} src={bkBtn2} />
          {type === 'NOTICE' ? <h1>{notice}</h1> : <h1>{event}</h1>}
        </Flex>
        {/* <MenuBtn src={menuBtn3}/> */}
      </StyledTopFixedBar>
      <StyledSelectBar ref={refSelectBar} type={type}>
        <h1 onClick={onClickNotice}>{notice}</h1>
        <h2 onClick={onClickEvent}>{event}</h2>
        <MovingBar left={pos} />
      </StyledSelectBar>
    </TopFixedBlock>
  );
}

export default TopFixedBarPostList;

const StyledTopFixedBar = styled(TopFixedBar)`
  position: relative;
  background-color: white;
  border-bottom: 2px solid #ededf0;
`;

const StyledSelectBar = styled(SelectBar)<{ type: string }>`
  h1 {
    position: absolute;
    left: 25%;
    top: 50%;
    transform: translate(-50%, -50%);

    ${props =>
      props.type === 'NOTICE'
        ? 'color: #111; font-weight: 600;'
        : 'color: var(--Font-04_Gray, #767676); font-weight: 400;'}

    text-align: center;
    font-size: 16px;
    font-style: normal;
    line-height: 24px; /* 150% */

    cursor: pointer;
  }

  h2 {
    position: absolute;
    left: 75%;
    top: 50%;
    transform: translate(-50%, -50%);

    ${props =>
      props.type === 'EVENT'
        ? 'color: #111; font-weight: 600;'
        : 'color: var(--Font-04_Gray, #767676); font-weight: 400;'}
    text-align: center;
    font-size: 16px;
    font-style: normal;
    line-height: 24px; /* 150% */

    cursor: pointer;
  }
`;
