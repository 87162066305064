/* eslint-disable no-nested-ternary */

import styled from 'styled-components';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { latlngState } from 'src/recoils/map.atom';
import {
  activeCategoryState,
  dayState,
  isNightState,
  markerPopupState,
} from 'src/recoils/user-mobile.atom';
import { getMapOption } from 'src/utils/map/getMapOption';
import setMarkerOff from 'src/utils/map/setMarkerOff';

import { apiBooth, apiCategory } from 'src/apis/utils/map';

import { ICategoryInfo } from 'src/interfaces/user-mobile/map.interface';
import { IFestivalOutlet } from 'src/interfaces/overview.interface';
import { makeFixedMarker } from 'src/utils/map/makeFixedMarker';
import { makeMarker } from 'src/utils/map/makeMarker';

import makeOverlay from 'src/utils/map/makeOverlay';
import getSubAddress from 'src/utils/getSubAddress';
import { INIT_MAP_OPTION } from 'src/consts/map/overview.const';
import { IS_ONLY_MOBILE } from 'src/consts/config.const';
import LocateButton from './NowLocateBtn';
import ClickInfo from './click/ClickPopup/ClickPopup';
import Category from './mapCategory';

function ComponentMap() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const [zoom, setZoom] = useState(INIT_MAP_OPTION.zoom);
  // const [prevZoom, setPrevZoom] = useState(INIT_MAP_OPTION.zoom);
  const mapRef = useRef<naver.maps.Map>();
  const { festivalId } = useOutletContext<IFestivalOutlet>();
  const activeCategory = useRecoilValue(activeCategoryState);
  const [markerPopup, setMarkerPopup] = useRecoilState(markerPopupState);
  const [, setMarkers] = useState<naver.maps.Marker[]>([]);
  const [, setFixedMarkers] = useState<naver.maps.Marker[]>([]);

  const subAddress = getSubAddress(IS_ONLY_MOBILE);
  const day = useRecoilValue(dayState);
  const isNight = useRecoilValue(isNightState);
  const onMarker = useRef<naver.maps.Marker>();
  const navigate = useNavigate();
  const onMarkerInfo = useRef({});
  const locationInfo = useLocation().state;
  const latlng = useRecoilValue(latlngState);
  let stampBoothId = 0;

  const { data: markerImg } = useQuery({
    queryKey: ['markerImg', activeCategory],
    queryFn: () =>
      apiCategory.getCategoryImg(activeCategory).then(res => {
        return res.data.icon;
      }),
  });

  const { data: boothList } = useQuery({
    queryKey: ['boothList', day, activeCategory],
    queryFn: () =>
      apiBooth.getBoothes(activeCategory).then(boothRes => boothRes.data),
  });

  const { data: fixedCategoryId } = useQuery({
    queryKey: ['fixedCategoryId'],
    queryFn: () =>
      apiCategory.getCategory(festivalId).then(res => {
        const fixedCategory = res.data.find(
          (e: ICategoryInfo) => e.categoryName === '고정 부스',
        );
        return fixedCategory.mapCategoryId;
      }),
    staleTime: Infinity, // 이 데이터를 무한히 캐시하여 불필요한 재호출 방지
  });

  const { data: fixedBoothList } = useQuery({
    queryKey: ['fixedBoothList ', day],
    queryFn: () =>
      apiBooth.getBoothes(fixedCategoryId).then(boothRes => {
        return boothRes.data;
      }),
    enabled: !!fixedCategoryId, // fixedCategoryId가 로드된 후에만 실행
  });

  useEffect(() => {
    async function initializeMap() {
      if (!festivalId) {
        navigate('/');
        return;
      }

      if (!mapRef.current) {
        mapRef.current = new naver.maps.Map('map', getMapOption(latlng));

        naver.maps.Event.addListener(mapRef.current, 'zoom_changed', _zoom => {
          setZoom(_zoom);
        });

        naver.maps.Event.addListener(mapRef.current, 'click', () => {
          setMarkerPopup(0);
          if (onMarker.current) {
            setMarkerOff(onMarker.current, onMarkerInfo.current);
            onMarker.current = undefined;
          }
        });
      }

      if (locationInfo) {
        stampBoothId = locationInfo.stampBoothId;
        const res = await apiBooth.getBoothSingle(stampBoothId);
        const location = new naver.maps.LatLng(
          res.data.latitude,
          res.data.longitude,
        );
        mapRef.current?.morph(location, 20, { duration: 500 }); // 500ms 동안 부드럽게 줌과 위치 변경
        setMarkerPopup(stampBoothId);
      } else setMarkerPopup(0);

      makeOverlay(mapRef);
    }
    initializeMap();
  }, []);

  useEffect(() => {
    // deleteMarkers();
    // && prevZoom >= INIT_MAP_OPTION.zoom + 1
    makeMarker(
      mapRef,
      boothList,
      markerImg,
      day,
      isNight,
      zoom,
      setMarkers,
      setMarkerPopup,
    );

    makeFixedMarker(
      mapRef,
      day,
      isNight,
      zoom,
      setFixedMarkers,
      setMarkerPopup,
      fixedBoothList,
    );
  }, [fixedBoothList, boothList, zoom]); // 의존성 배열 추가;

  useEffect(() => {
    // 화면 높이 설정
    const setHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    setHeight();
    window.addEventListener('resize', setHeight);
    return () => window.removeEventListener('resize', setHeight);
  }, []);

  return (
    <MapContainer id="map">
      <Category isManage={false} />
      <LocateButton mapRef={mapRef} naver={naver} />
      <Outlet />
      {markerPopup !== 0 ? (
        // <ClickInfo setPopup={setMarkerPopup} id={markerPopup} />
        <ClickInfo id={markerPopup} />
      ) : subAddress ? (
        '' // <ChangeNight />
      ) : (
        ''
      )}
    </MapContainer>
  );
}

export default ComponentMap;

const MapContainer = styled.div`
  /* height: 80vh; */
  height: calc(var(--vh, 1vh) * 100);
`;
